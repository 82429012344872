// import { createContext, useEffect, useReducer } from "react";
// // import axios from 'axios';
// import { MatxLoading } from "app/components";
// import { useSelector } from "react-redux";
// import { useMemo } from "react";
// import {
//   actionProfile,
//   actionLogin,
// } from "middleware/store/actions/auth/index";
// import { actionSetAccountList } from "middleware/store/actions/account/accountList";
// import { actionSetBalanceList } from "middleware/store/actions/balance/balanceList";
// import {
//   actionDashboardTotalProfit,
//   actionDashboardTotalWithdrawal,
//   actionDashboardTotalDeposit,
//   actionDashboardV1Daily,
//   actionDashboardV1EquityBalance,
//   actionDashboardV1Monthly,
//   actionDashboardV1Yearly,
// } from "middleware/store/actions/dashboard/dashboardVpsV1";
// import { actionSetProfitList } from "middleware/store/actions/profit/profitList";
// import { actionSetVpsDetail } from "middleware/store/actions/vps/vpsDetail";
// import { actionSetVpsList } from "middleware/store/actions/vps/vpsList";
// // import axios from "axios";

// const initialState = {
//   user: null,
//   isInitialised: false,
//   isAuthenticated: false,
// };

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "INIT": {
//       const { isAuthenticated, user } = action.payload;
//       return { ...state, isAuthenticated, isInitialised: true, user };
//     }

//     case "LOGIN": {
//       const { user } = action.payload;
//       return { ...state, isAuthenticated: true, user };
//     }

//     case "LOGOUT": {
//       return { ...state, isAuthenticated: false, user: null };
//     }

//     case "REGISTER": {
//       const { user } = action.payload;

//       return { ...state, isAuthenticated: true, user };
//     }

//     default:
//       return state;
//   }
// };

// const AuthContext = createContext({
//   ...initialState,
//   method: "JWT",
//   login: () => {},
//   logout: () => {},
//   register: () => {},
// });

// export const AuthProvider = ({ children }) => {
//   const dataDummy = useMemo(
//     () => ({
//       id: 1,
//       role: "SA",
//       name: "Jason Alexander",
//       username: "jason_alexander",
//       email: "jason@ui-lib.com",
//       avatar: "/assets/images/face-6.jpg",
//       age: 25,
//     }),
//     []
//   );

//   const dataUser = useSelector((state) => state.userProfile.userProfile);

//   const [state, dispatch] = useReducer(reducer, initialState);

//   const login = async () => {
//     dispatch({ type: "LOGIN", payload: { dataDummy } });
//   };

//   const register = async () => {
//     dispatch({ type: "REGISTER", payload: { dataDummy } });
//   };

//   const logout = async () => {
//     await dispatch(actionLogin(null));
//     await dispatch(actionProfile(null));
//     await dispatch(actionSetAccountList(null));
//     await dispatch(actionSetBalanceList(null));
//     await dispatch(actionDashboardV1Daily(null));
//     await dispatch(actionDashboardV1Monthly(null));
//     await dispatch(actionDashboardV1Yearly(null));
//     await dispatch(actionDashboardV1EquityBalance(null));
//     await dispatch(actionSetProfitList());
//     await dispatch(actionSetVpsDetail(null));
//     await dispatch(actionSetVpsList(null));
//     await dispatch(actionDashboardTotalProfit(null));
//     await dispatch(actionDashboardTotalWithdrawal(null));
//     await dispatch(actionDashboardTotalDeposit(null));
//     await dispatch({ type: "LOGOUT" });

//     console.log("logout");
//   };

//   useEffect(() => {
//     (async () => {
//       // OLD METHOD
//       /* try {
//         const { data } = await axios.get('/api/auth/profile');
//         dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: data.user } });
//       } catch (err) {
//         console.error(err);
//         dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
//       } */

//       // NEW METHOD
//       if (
//         dataUser === null ||
//         Object.keys(dataUser).length === 0 ||
//         dataUser === undefined
//       ) {
//         dispatch({
//           type: "INIT",
//           payload: { isAuthenticated: false, user: null },
//         });
//       } else {
//         dispatch({
//           type: "INIT",
//           payload: { isAuthenticated: true, user: dataDummy },
//         });
//       }
//     })();
//   }, [dataDummy, dataUser]);

//   // SHOW LOADER
//   if (!state.isInitialised) return <MatxLoading />;

//   return (
//     <AuthContext.Provider
//       value={{ ...state, method: "JWT", login, logout, register }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthContext;

import { createContext, useEffect, useReducer } from "react";
import { MatxLoading } from "app/components";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import axios from "axios";
import {
  actionProfile,
  actionLogin,
} from "middleware/store/actions/auth/index";
import { actionSetAccountList } from "middleware/store/actions/account/accountList";
import { actionSetBalanceList } from "middleware/store/actions/balance/balanceList";
import {
  actionDashboardTotalProfit,
  actionDashboardTotalWithdrawal,
  actionDashboardTotalDeposit,
  actionDashboardV1Daily,
  actionDashboardV1EquityBalance,
  actionDashboardV1Monthly,
  actionDashboardV1Yearly,
} from "middleware/store/actions/dashboard/dashboardVpsV1";
import { actionSetProfitList } from "middleware/store/actions/profit/profitList";
import { actionSetVpsDetail } from "middleware/store/actions/vps/vpsDetail";
import { actionSetVpsList } from "middleware/store/actions/vps/vpsList";

// Initial State
const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
};

// Reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }
    case "LOGIN": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }
    case "REGISTER": {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: () => {},
  logout: () => {},
  register: () => {},
});

export const AuthProvider = ({ children }) => {
  const dataDummy = useMemo(
    () => ({
      id: 1,
      role: "SA",
      name: "Jason Alexander",
      username: "jason_alexander",
      email: "jason@ui-lib.com",
      avatar: "/assets/images/face-6.jpg",
      age: 25,
    }),
    []
  );

  const dataUser = useSelector((state) => state.userProfile.userProfile);
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async () => {
    dispatch({ type: "LOGIN", payload: { dataDummy } });
  };

  const register = async () => {
    dispatch({ type: "REGISTER", payload: { dataDummy } });
  };

  const logout = async () => {
    await dispatch(actionLogin(null));
    await dispatch(actionProfile(null));
    await dispatch(actionSetAccountList(null));
    await dispatch(actionSetBalanceList(null));
    await dispatch(actionDashboardV1Daily(null));
    await dispatch(actionDashboardV1Monthly(null));
    await dispatch(actionDashboardV1Yearly(null));
    await dispatch(actionDashboardV1EquityBalance(null));
    await dispatch(actionSetProfitList(null));
    await dispatch(actionSetVpsDetail(null));
    await dispatch(actionSetVpsList(null));
    await dispatch(actionDashboardTotalProfit(null));
    await dispatch(actionDashboardTotalWithdrawal(null));
    await dispatch(actionDashboardTotalDeposit(null));
    dispatch({ type: "LOGOUT" });
    console.log("User logged out");
  };

  const checkSession = async (email) => {
    try {
      const url = "https://3dvam.info/api/check_session.php";
      const params = new URLSearchParams();
      params.append("email", email);

      const response = await axios.post(url, params, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const sessionStatus = response?.data?.status || "first";
      console.log(sessionStatus, "sessionStatus");

      if (sessionStatus === "no") {
        logout();
      }
    } catch (error) {
      console.error("Session check failed:", error);
      logout();
    }
  };

  useEffect(() => {
    (async () => {
      if (!dataUser || Object.keys(dataUser).length === 0) {
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: false, user: null },
        });
      } else {
        dispatch({
          type: "INIT",
          payload: { isAuthenticated: true, user: dataUser },
        });
        await checkSession(dataUser.email);
      }
    })();
  }, [dataUser]);

  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider
      value={{ ...state, method: "JWT", login, logout, register }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
